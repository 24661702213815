import { Link } from 'react-router-dom'
import { Container, Heading, Box, Text, List, ListItem, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Layout from '../../components/layouts/article'
import { Meta } from '../../components/project'

import imageOne from '../../images/lalalai-02.gif'
import imageTwo from '../../images/lalalai-02.png'
import imageThree from '../../images/lalalai-03.png'
import imageFour from '../../images/lalalai-04.png'
import imageCover from '../../images/lalal-cover.jpg'

const LALALAI = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure()
    const { isOpen: isOpenThree, onOpen: onOpenThree, onClose: onCloseThree } = useDisclosure()
    const { isOpen: isOpenFour, onOpen: onOpenFour, onClose: onCloseFour } = useDisclosure()

    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                    <Box align="left" my={8}>
                        <Text fontSize="sm">
                            <Link style={{ textDecoration: 'underline' }} to={'/'}>
                                Home
                            </Link>{' '}
                            &gt; Project &gt; LALAL.AI{' '}
                        </Text>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="lg">
                        LALAL.AI
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.5 }} mb={6}>
                    <Box align="left" my={12}>
                        <p>Redesigned interface for seperating audio stems using LALAL.AI's audio stem splitting AI technology.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.7 }} mb={6}>
                    <List ml={4} my={4}>
                        <ListItem>
                            <Meta>Website</Meta>
                            <a target="_blank" href="https://lalalai.dissolve.group/">
                                https://lalalai.dissolve.group/ <ExternalLinkIcon mx="2px" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <Meta>Technology Stack</Meta>
                            <span>ReactJS, LALAL.AI API, Framer Motion, AWS Amplify</span>
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpen} src={imageOne} alt="Login" />
                        </Box>
                    </motion.div>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenTwo} src={imageFour} alt="Wireframe concept" />
                        </Box>
                    </motion.div>
                    <Box align="left" mt={12} mb={6}></Box>
                    <Box align="left" my={0}>
                        <p>Early wireframe concept to improve UX.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenThree} src={imageTwo} alt="Progress" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Progress bar and up-to-date information to keep user informed while seperation engine performs.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenFour} src={imageThree} alt="Image export" />
                        </Box>
                    </motion.div>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.3 }} mb={6}>
                    <Box align="left" my={12}>
                        <span>Visit </span>
                        <a style={{ fontWeight: '700' }} href="https://lalalai.dissolve.group/" target="_blank">
                            LALAL.AI
                        </a>
                        .
                    </Box>
                </motion.div>
            </Container>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageCover} alt="lalal large one" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenTwo} onClose={onCloseTwo}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageFour} alt="lalal large two" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenThree} onClose={onCloseThree}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageTwo} alt="lalal large three" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenFour} onClose={onCloseFour}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageThree} alt="lalal large four" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}

export default LALALAI
