import { Link } from 'react-router-dom'
import { Container, Heading, Box, Text, List, ListItem, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Layout from '../../components/layouts/article'
import { Meta } from '../../components/project'

import imageOne from '../../images/ss_1.jpg'
import imageTwo from '../../images/ss_23.jpg'
import imageThree from '../../images/ss_3.jpg'
import imageFour from '../../images/ss_4.jpg'
import imageFive from '../../images/ss_5.jpg'

const SeamShift = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure()
    const { isOpen: isOpenThree, onOpen: onOpenThree, onClose: onCloseThree } = useDisclosure()
    const { isOpen: isOpenFour, onOpen: onOpenFour, onClose: onCloseFour } = useDisclosure()
    const { isOpen: isOpenFive, onOpen: onOpenFive, onClose: onCloseFive } = useDisclosure()

    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                    <Box align="left" my={8}>
                        <Text fontSize="sm">
                            <Link style={{ textDecoration: 'underline' }} to={'/'}>
                                Home
                            </Link>{' '}
                            &gt; Project &gt; Seam Shift{' '}
                        </Text>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="lg">
                        Seam Shift
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.5 }} mb={6}>
                    <Box align="left" my={12}>
                        <p>Major League Baseball pitching advanced statistical analysis. Includes gambling odds for the Cy Young (best pitcher in baseball).</p>
                        &nbsp;
                        <p>Node JS application scrapes numerous sources daily for raw data before being processed and inserted into a NoSQL database.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.7 }} mb={6}>
                    <List ml={4} my={4}>
                        <ListItem>
                            <Meta>Website</Meta>
                            <span>In development (beta)</span>
                        </ListItem>
                        <ListItem>
                            <Meta>Technology Stack</Meta>
                            <span>Javascript, React, Node JS, Firebase, Parser, ChartJS</span>
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpen} src={imageOne} alt="seam shift one" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Dashboard showing pitcher schedule for today and tomorrow along with current odds.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenTwo} src={imageTwo} alt="seam shift two" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Individual pitcher visuals tracking statisitical changes over course of the season.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenThree} src={imageThree} alt="seam shift three" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Sortable table of American League pitchers and charts.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenFour} src={imageFour} alt="seam shift four" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Showing latest news for an individual pitcher which is scraped daily.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenFive} src={imageFive} alt="seam shift five" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Showing latest odds across multiple services for an individual pitcher which is scraped daily.</p>
                    </Box>
                </motion.div>
            </Container>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageOne} alt="seam shift five large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenTwo} onClose={onCloseTwo}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageTwo} alt="seam shift two large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenThree} onClose={onCloseThree}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageThree} alt="seam shift three large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenFour} onClose={onCloseFour}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageFour} alt="seam shift four large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenFive} onClose={onCloseFive}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageFive} alt="seam shift five large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}

export default SeamShift
