import { Link } from 'react-router-dom'
import { Container, Heading, Box, Text, List, ListItem, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Layout from '../../components/layouts/article'
import { Meta } from '../../components/project'

import imageOne from '../../images/gap-01.png'
import imageTwo from '../../images/gap-02.png'
import imageThree from '../../images/gap-01.gif'

const TheGapAndTheGain = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure()

    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                    <Box align="left" my={8}>
                        <Text fontSize="sm">
                            <Link style={{ textDecoration: 'underline' }} to={'/'}>
                                Home
                            </Link>{' '}
                            &gt; Project &gt; The GAP and the GAIN{' '}
                        </Text>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="lg">
                        The GAP and the GAIN
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.5 }} mb={6}>
                    <Box align="left" my={12}>
                        <p>Tool for recording answers to the exercises found in the book: The Gap and the Gain.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.7 }} mb={6}>
                    <List ml={4} my={4}>
                        <ListItem>
                            <Meta>Website</Meta>
                            <span>N/A</span>
                        </ListItem>
                        <ListItem>
                            <Meta>Technology Stack</Meta>
                            <span>ReactJS, Supabase, Postgres, Three.js, AWS Amplify</span>
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpen} src={imageOne} alt="Login" />
                        </Box>
                    </motion.div>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenTwo} src={imageTwo} alt="List" />
                        </Box>
                    </motion.div>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box _hover={{ boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)' }} align="left" my={12}>
                            <Image src={imageThree} alt="Image export" />
                        </Box>
                    </motion.div>
                </motion.div>
            </Container>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageOne} alt="gap large one" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenTwo} onClose={onCloseTwo}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageTwo} alt="gap large two" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}

export default TheGapAndTheGain
