import { Link } from 'react-router-dom'
import { Container, Heading, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Layout from '../components/layouts/article'

const FourOhFour = () => {
    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading mb={4} as="h3" size="lg">
                        404. Not found.
                    </Heading>
                    <Text>
                        Back to the <Link to="/">homepage.</Link>
                    </Text>
                </motion.div>
            </Container>
        </Layout>
    )
}

export default FourOhFour
