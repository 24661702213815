import { Link } from 'react-router-dom'
import { Container, Heading, Box, Text, List, ListItem, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Layout from '../../components/layouts/article'
import { Meta } from '../../components/project'

import imageOne from '../../images/espn-1.jpg'
import imageTwo from '../../images/espn-2.jpg'
import imageThree from '../../images/espn-3.jpg'

const ESPN = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure()

    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                    <Box align="left" my={8}>
                        <Text fontSize="sm">
                            <Link style={{ textDecoration: 'underline' }} to={'/'}>
                                Home
                            </Link>{' '}
                            &gt; Project &gt; ESPN Mute{' '}
                        </Text>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="lg">
                        ESPN Mute
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.5 }} mb={6}>
                    <Box align="left" my={12}>
                        <p>
                            Chrome extension that mutes ESPN music during commercials. This is done through tab sharing, grabbing a still image from the stream, and comparing it to a bank of images.
                            Through image comparison the extension can determine if the commercial is running, and if so, muting the tab.
                        </p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.7 }} mb={6}>
                    <List ml={4} my={4}>
                        <ListItem>
                            <Meta>Website</Meta>
                            <a target="_blank" href="https://github.com/egapage/espn-block">
                                https://github.com/egapage/espn-block <ExternalLinkIcon mx="2px" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <Meta>Technology Stack</Meta>
                            <span>Javascript, Chrome Manifest V3</span>
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box _hover={{ boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)' }} align="left" my={12}>
                            <Image src={imageOne} alt="espn mute popup" />
                        </Box>
                    </motion.div>

                    <Box align="left" my={0}>
                        <p>Chrome extension popup design.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpen} src={imageTwo} alt="espn mute popup" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Extension loaded in Chrome.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.1 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenTwo} src={imageThree} alt="espn mute guide" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Streaming page showing image match differentials in the console.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.3 }} mb={6}>
                    <Box align="left" my={12}>
                        <span>Visit the Github repo </span>
                        <a style={{ fontWeight: '700' }} href="https://github.com/egapage/espn-block" target="_blank">
                            here
                        </a>
                        .
                    </Box>
                </motion.div>
            </Container>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageTwo} alt="espn mute popup large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenTwo} onClose={onCloseTwo}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageThree} alt="espn mute guide large" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}

export default ESPN
