import { Container, Heading, Box, SimpleGrid } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import GridItem from '../components/grid-item'
import Layout from '../components/layouts/article'
import { IoLogoGithub } from 'react-icons/io5'

import cover_gap from '../images/thegapandthegain.jpg'
import cover_ss from '../images/ss_logo.png'
import cover_lalalai from '../images/lalalai.jpg'
import cover_espn from '../images/cover-espn.jpg'

const Home = () => {
    return (
        <Layout>
            <Container>
                <Box display={{ md: 'flex' }}>
                    <Box flexGrow={1}>
                        <Heading as="h2" size="lg">
                            Eric Page
                        </Heading>
                        <p>Calgary, Alberta, Canada</p>
                    </Box>
                </Box>
                <Box align="left" my={12}>
                    <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                        <Heading my={2} as="h3" size="md">
                            Full-Stack Web Developer
                        </Heading>
                        <p style={{ marginBottom: 5 }}>Javascript & Typescript, NodeJS, ReactJS, React Native, PHP, HTML/CSS.</p>
                        <p>AWS Certified Solutions Architect – Associate</p>
                    </motion.div>
                </Box>
                <Box align="left" my={12}>
                    <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                        <Heading my={2} as="h3" size="md">
                            Relevant Experience
                        </Heading>
                        <p style={{ marginBottom: 5 }}>
                            <strong>Lead Frontend Engineer</strong> - <i>Bitcoin Depot</i> (2022-2024)
                        </p>
                        <p style={{ marginBottom: 5 }}>
                            <strong>Frontend Developer</strong> - <i>114 Degrees West</i> (2020-2022)
                        </p>
                        <p style={{ marginBottom: 5 }}>
                            <strong>Full Stack Developer</strong> - <i>Freshwater Creative</i> (2017-2020)
                        </p>
                    </motion.div>
                </Box>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="md">
                        Projects
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.4 }} mb={6}>
                    <Box align="center" my={4}>
                        <SimpleGrid columns={2} spacing={10} minChildWidth="240px">
                            <GridItem url="lalalai" image={cover_lalalai} title="LALAL.AI" />
                            <GridItem url="seamshift" image={cover_ss} title="Seam Shift" />
                            <GridItem url="thegapandthegain" image={cover_gap} title="The GAP and the GAIN" />
                            <GridItem url="espn" image={cover_espn} title="ESPN Mute (Chrome extension)" />
                        </SimpleGrid>
                    </Box>
                    <Box my={12}>
                        <Heading my={3} as="h3" size="md">
                            Github
                        </Heading>
                        <div style={{ position: 'relative' }}>
                            <motion.div
                                initial={{ scale: 1, color: '#B9BBBE' }}
                                whileHover={{
                                    scale: 1.1,
                                    color: '#000000',
                                }}
                                transition={{ type: 'spring', stiffness: 400 }}
                                style={{ originX: 0.05, originY: 0.5 }}
                                mb={6}
                            >
                                <IoLogoGithub style={{ width: '40px', display: 'block' }} size="small" />
                                <a
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        bottom: 0,
                                    }}
                                    target="_blank"
                                    href="https://github.com/egapage"
                                ></a>
                            </motion.div>
                        </div>
                    </Box>
                </motion.div>
            </Container>
        </Layout>
    )
}

export default Home
