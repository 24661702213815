import { Link } from 'react-router-dom'
import { Box, Image, Text, LinkBox } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const GridItem = ({ image, title, url }) => {
    const divMotion = {
        rest: {
            x: 0,
            transition: {
                duration: 2,
                type: 'tween',
                ease: 'easeIn',
            },
        },
        hover: {
            x: 5,
            transition: {
                duration: 0.4,
                type: 'tween',
                ease: 'easeOut',
            },
        },
    }

    return (
        <Box className="grid-item">
            <LinkBox cursor="pointer">
                <motion.div variants={divMotion} whileHover="hover">
                    <motion.div className="grid-item__Image">
                        <Image src={image} alt={title} className="grid-item-thumbnail" placeholder="blur" loading="lazy" />
                    </motion.div>
                    <Link className="link-overlay" to={`/project/${url}`}>
                        <Text mt={2}>{title}</Text>
                    </Link>
                </motion.div>
            </LinkBox>
        </Box>
    )
}

export default GridItem
